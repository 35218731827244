import { Pagination } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getList } from '../../api/collections';
import FormElement from '../../components/Form/FormElement';
import Layout from '../../components/Layout'
import Spinner from '../../components/spinner';
import { useGetData } from '../../hooks/useGetData';
import usePostData from '../../hooks/usePostData';

const data = {
  data: [
    {
      'standard_id': 1,
      'standard_name': "Nursery",
      'total_days': '23'
    },
    {
      'standard_id': 2,
      'standard_name': "First",
      'total_days': '23'
    },
    {
      'standard_id': 3,
      'standard_name': "Second",
      'total_days': '23'
    },
    {
      'standard_id': 4,
      'standard_name': "Third",
      'total_days': '23'
    },
    {
      'standard_id': 5,
      'standard_name': "Fourth",
      'total_days': '23'
    },
  ]
}

const Attendance = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [standardData, setStandardData] = useState([])
  const initialFilterData = {
    year_id: searchParams.get('year_id') || '',
    term_id: searchParams.get('term_id') || '',
    page_no: searchParams.get('_p') || 0,
    limit: 15,
  }
  const [resultFilterData, setResultFilterData] = useState(initialFilterData)
  const options = {
    standardArray: [],
    yearArray: [],
    termArray: []
  }
  const [optionsArray, setOptionsArray] = useState(options)

  const fetchOptionData = (type) => {
    getList(type)
      .then(res => {
        if (type === "SchoolTerm") {
          setOptionsArray(prevState => ({
            ...prevState,
            termArray: res.data
          }));
        } else if (type === "Year") {
          let tempId = res.data.filter((i) => i.current_year === "1")
          setOptionsArray(prevState => ({
            ...prevState,
            yearArray: res.data
          }));
          setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const handleSetFromData = (e) => {
    let $this = e.target
    let data = {
      ...resultFilterData,
      [$this.name]: $this.value
    }
    searchParams.set($this.name, $this.value)
    setSearchParams(searchParams)
    setResultFilterData(data)
  }

  const { handleGetPostList } = useGetData('fetchAttendanceStandardList', {
    onSuccess: () => { }
  })

  const { handlePostData } = usePostData({ queryName: "", url: "createAttandanceDays" })
  const handleFilterFromData = (e) => {
    e.preventDefault()
    handleGetPostList.mutate(resultFilterData, {
      onSuccess: (data, variables, context) => {
        setStandardData(data.data)
      },
    })
  }
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const saveTotalDays = (e, idx, stdId) => {
    let temp = [...standardData]
    let value = e.target.value
    temp[idx].total_days = value
    setStandardData(temp)
    optimizedFn(value, stdId)
  }
  const saveTotalDaysToServer = (days, stdId) => {
    handlePostData.mutate({ standard_id: stdId, total_days: days, ...resultFilterData })
  }
  const optimizedFn = useCallback(debounce(saveTotalDaysToServer), []);

  useEffect(() => {
    const typeList = ["Year", "SchoolTerm"]
    typeList.forEach((type) => {
      fetchOptionData(type)
    })
  }, [])

  return (
    <Layout>
      <div>
        <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
          <div className='grid grid-cols-5 gap-x-4 items-end'>
            <FormElement
              label={"Select Year"}
              name={'year_id'}
              element={'select'}
              required={true}
              value={resultFilterData.year_id}
              optionValue={optionsArray.yearArray}
              errorMessage='Please Select Year!'
              onChange={handleSetFromData}
            />
            <FormElement
              label={"Select Term"}
              name={'term_id'}
              element={'select'}
              required={true}
              optionValue={optionsArray.termArray}
              errorMessage='Please Select Term!'
              onChange={handleSetFromData}
              value={resultFilterData.term_id}
            />
            <div className='flex'>
              <button className='primaryButton w-full'>Fetch Standard List</button>
            </div>
          </div>
        </form>
      </div>
      <div>
        {handleGetPostList.isLoading
          ? <Spinner />
          : handleGetPostList?.data?.data.length === 0 || !handleGetPostList?.data?.data ? null : <>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                      Sr.no
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                      Standard Name
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                      Days
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {standardData?.map((std, idx) => {
                    const { id, name, total_days } = std
                    return <tr key={idx}>
                      <td className="border-dashed border-t border-gray-200 userId text-center">
                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                          {idx + 1}
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 lastName">
                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                          {name}
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 lastName text-center">
                        <input type="number" name='marks' value={total_days} className={`w-16 text-center appearance-none p-1 border`} onChange={(e) => saveTotalDays(e, idx, id)} />
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className=" my-7">
                    <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetPostList?.data?.total_count / resultFilterData.limit)} pageNo={handleGetPostList?.data?.page_no}/>
                </div> */}
          </>
        }
      </div>
    </Layout>
  )
}

export default Attendance