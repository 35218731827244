import React from 'react'
import { CreditCardIcon, LogoutIcon, OfficeBuildingIcon, UserGroupIcon, UsersIcon, TemplateIcon, ReceiptTaxIcon, BookOpenIcon, XIcon, DocumentIcon, ClipboardCheckIcon, ClipboardIcon, } from '@heroicons/react/solid'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { decode } from 'base-64'
import { useState } from 'react';
import { Modal } from '@mui/material';
import FormElement from '../Form/FormElement';
import Spinner from '../spinner';
import { ADMIN, FEES, TEACHER } from '../../config';

const cssActiveLink = 'space-x-3 bg-[color:var(--color2)] text-[color:var(--primarycolor)]'
const cssUnActiveLink = 'hover:bg-[color:var(--color2)] hover:text-[color:var(--primarycolor)]'


const cookies = new Cookies
function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation()
    const userRole = decode(cookies.get("_role_token"));
    const userSubRole = cookies.get("_sub_role");
    console.log(userSubRole);
    const handleLogout = () => {
        cookies.remove("_pn_token", { path: '/' })
        cookies.remove("_role_token", { path: '/' })
        navigate('/');

    }

    const [feeBookModal, setFeeBookModal] = useState(false)
    const handleOpenFeeBookModal = () => {
        setFeeBookModal(true)
    }

    const handleCloseFeeBookModal = () => {
        setFeeBookModal(false)
    }

    const handleSaveFromData = () => { }
    return (
        <>
            <div className=" bg-[color:var(--primarycolor)] w-[17rem] h-screen p-4 fixed overflow-auto">
                <div className=' pb-8'>
                    <div>
                        <p className=' text-center  text-2xl text-white font-bold mb-10 roboto'><NavLink to={"/dashboard"}>Panday School</NavLink></p>
                    </div>
                    <div className=' divide-y divide-white'>
                        {
                            userRole === ADMIN ?
                                <>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/dashboard'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <TemplateIcon className='w-6' />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/students'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <UserGroupIcon className='w-6' />
                                            <span>Students</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/subjects'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span>Subjects</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/teachers'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <UsersIcon className='w-6' />
                                            <span>Teachers</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/standard-and-section'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <OfficeBuildingIcon className='w-6' />
                                            <span>Standard & Section</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/attendance'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span>Attendance</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/results'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <ClipboardCheckIcon className='w-6' />
                                            <span>Results</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/fees'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <CreditCardIcon className='w-6' />
                                            <span>Fees</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/particulars'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <ReceiptTaxIcon className='w-6' />
                                            <span>Particulars</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/fee-book-generation'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : `${location.pathname === '/standard-fee-book' && cssActiveLink} ${cssUnActiveLink}`}`}>
                                            <DocumentIcon className='w-6' />
                                            <span>Fee Book</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/fee-user-management'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : `${location.pathname === '/fee-user-management' && cssActiveLink} ${cssUnActiveLink}`}`}>
                                            <DocumentIcon className='w-6' />
                                            <span>Fee Management</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/receipts'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <ReceiptTaxIcon className='w-6' />
                                            <span>Receipts</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/report'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <DocumentIcon className='w-6' />
                                            <span>Report</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/terms'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span>Terms</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/marks-scheme'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span>Marks Scheme</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/standard-management'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span className='text-start'>Standard Management</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/ledger'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span className='text-start'>Ledger</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/year'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <BookOpenIcon className='w-6' />
                                            <span className='text-start'>Year Change</span>
                                        </NavLink>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <a target='blank' href={'https://me.wisemonkeys.info/'} className={`flex items-center px-3 py-2 rounded space-x-3`}>
                                            <ClipboardIcon className='w-6' />
                                            <span>Certificate</span>
                                        </a>
                                    </div>
                                    <div className=" text-white text-lg roboto text-center py-2">
                                        <NavLink to={'/school-reopen'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                            <OfficeBuildingIcon className='w-6' />
                                            <span className='text-start'>School Reopen</span>
                                        </NavLink>
                                    </div>
                                </>
                                : userRole === FEES ?
                                    <>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/dashboard'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <TemplateIcon className='w-6' />
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/students'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <UserGroupIcon className='w-6' />
                                                <span>Students</span>
                                            </NavLink>
                                        </div>
                                        {/* <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/standard-and-section'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <OfficeBuildingIcon className='w-6' />
                                                <span>Standard & Section</span>
                                            </NavLink>
                                        </div> */}
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/fees'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <CreditCardIcon className='w-6' />
                                                <span>Fees</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/particulars'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <ReceiptTaxIcon className='w-6' />
                                                <span>Particulars</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/fee-book-generation'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <DocumentIcon className='w-6' />
                                                <span>Fee Book</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/roll-numbers'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <UsersIcon className='w-6' />
                                                <span>Fee Book Number</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/receipts'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <ReceiptTaxIcon className='w-6' />
                                                <span>Receipts</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/ledger'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <BookOpenIcon className='w-6' />
                                                <span className='text-start'>Ledger</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/report'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <DocumentIcon className='w-6' />
                                                <span>Report</span>
                                            </NavLink>
                                        </div>
                                        <div className=" text-white text-lg roboto text-center py-2">
                                            <NavLink to={'/year'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                <BookOpenIcon className='w-6' />
                                                <span className='text-start'>Year Change</span>
                                            </NavLink>
                                        </div>
                                    </>
                                    : userRole === "result" ?
                                        <>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/dashboard'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <TemplateIcon className='w-6' />
                                                    <span>Dashboard</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/students'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <UserGroupIcon className='w-6' />
                                                    <span>Students</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/subjects'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span>Subjects</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/teachers'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <UsersIcon className='w-6' />
                                                    <span>Teachers</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/standard-and-section'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <OfficeBuildingIcon className='w-6' />
                                                    <span>Standard & Section</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/attendance'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span>Attendance</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/roll-numbers'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <UsersIcon className='w-6' />
                                                    <span>Roll Number</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/results'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <ClipboardCheckIcon className='w-6' />
                                                    <span>Results</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/terms'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span>Terms</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/marks-scheme'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span>Marks Scheme</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/standard-management'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span className='text-start'>Standard Management</span>
                                                </NavLink>
                                            </div>
                                            <div className=" text-white text-lg roboto text-center py-2">
                                                <NavLink to={'/year'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                    <BookOpenIcon className='w-6' />
                                                    <span className='text-start'>Year Change</span>
                                                </NavLink>
                                            </div>
                                        </>
                                        : userRole === TEACHER ?
                                            <>
                                                <div className=" text-white text-lg roboto text-center py-2">
                                                    <NavLink to={'/students'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                        <UserGroupIcon className='w-6' />
                                                        <span>Students</span>
                                                    </NavLink>
                                                </div>
                                                <div className=" text-white text-lg roboto text-center py-2">
                                                    <NavLink to={'/subjects'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                        <BookOpenIcon className='w-6' />
                                                        <span>Subjects</span>
                                                    </NavLink>
                                                </div>
                                                <div className=" text-white text-lg roboto text-center py-2">
                                                    <NavLink to={'/marks-entry'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                        <BookOpenIcon className='w-6' />
                                                        <span className='text-start'>Marks Entry</span>
                                                    </NavLink>
                                                </div>
                                                <div className=" text-white text-lg roboto text-center py-2">
                                                    <NavLink to={'/roll-numbers'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                        <UsersIcon className='w-6' />
                                                        <span>Roll Number</span>
                                                    </NavLink>
                                                </div>
                                                {/* <div className=" text-white text-lg roboto text-center py-2">
                                                    <NavLink to={'/results'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                        <ClipboardCheckIcon className='w-6' />
                                                        <span>Results</span>
                                                    </NavLink>
                                                </div> */}
                                                {
                                                    userSubRole.includes("Class Teacher") || userSubRole.includes("Computer Teacher") ? (
                                                        <>
                                                            <div className=" text-white text-lg roboto text-center py-2">
                                                                <NavLink to={'/results'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                                    <ClipboardCheckIcon className='w-6' />
                                                                    <span>Results</span>
                                                                </NavLink>
                                                            </div>
                                                            <div className=" text-white text-lg roboto text-center py-2">
                                                                <NavLink to={'/attendance'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                                    <BookOpenIcon className='w-6' />
                                                                    <span>Attendance</span>
                                                                </NavLink>
                                                            </div>
                                                        </>
                                                    ) : <></>
                                                }
                                                {
                                                    userSubRole.includes("Computer Teacher") ? (
                                                        <div className=" text-white text-lg roboto text-center py-2">
                                                            <NavLink to={'/year'} className={({ isActive }) => `flex items-center px-3 py-2 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
                                                                <BookOpenIcon className='w-6' />
                                                                <span className='text-start'>Year Change</span>
                                                            </NavLink>
                                                        </div>
                                                    ) : <></>
                                                }


                                            </>
                                            : <></>
                        }
                        <div className=" text-white text-lg roboto text-center py-2">
                            <div onClick={handleLogout} className='cursor-pointer flex items-center px-3 py-2 rounded space-x-3 bg-[color:var(--color3)] hover:bg-[color:var(--color2)] hover:text-[color:var(--primarycolor)]'>
                                <LogoutIcon className='w-6' />
                                <span>Logout</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal disableEscapeKeyDown open={feeBookModal} onClose={handleCloseFeeBookModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Download Fee Book</h2>
                            <XIcon onClick={handleCloseFeeBookModal} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>

                            <FormElement
                                label={"Select Standard"}
                                name={'standard_type_id'}
                                placeholder={'Select Standard Type'}
                                element={'select'}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                            // onChange={handleSetFromData}
                            />
                            <FormElement
                                label={"Year"}
                                name={'standard_type_id'}
                                placeholder={'Select Standard Type'}
                                element={'select'}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                            // onChange={handleSetFromData}
                            />
                            {false ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Download</button>
                                </div>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Sidebar
