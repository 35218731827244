export const ENVIRONMENT = 'development' // production

export const TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Njc1NDcwMTgsIm5iZiI6MTY2NzU0NzAxOCwiZXhwIjoxNjY3OTA3MDE4LCJ1c2VybmFtZSI6ImFkbWluaXN0cmF0b3IiLCJpZCI6IjEiLCJpc3MiOiJQYW5kZXkgU2Nob29sIiwiYXVkIjoiUGFuZGV5IFNjaG9vbCJ9.Ooihzv441qabc7IfSk7faVIIUA5LPXVAYwJsv44gR54'

export const ADMIN = 'admin'
export const TEACHER = 'teachers'
export const FEES = 'fees'
// export const ADMIN = 'admin'
// export const ADMIN = 'admin'

export const MAX_LENGTH_FOR_TEXTAREA = 250

