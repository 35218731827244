import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import FormElement from '../../components/Form/FormElement'
import { useSearchParams } from 'react-router-dom';
import { getList } from '../../api/collections';
import usePostData from '../../hooks/usePostData';
import { useGetData } from '../../hooks/useGetData';
import Spinner from '../../components/spinner';

const SchoolReopen = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [standardData, setStandardData] = useState([])
    const initialFilterData = {
        year_id: searchParams.get('year_id') || '',
        page_no: searchParams.get('_p') || 0,
        limit: 15,
    }
    const [resultFilterData, setResultFilterData] = useState(initialFilterData)
    const options = {
        standardArray: [],
        yearArray: [],
    }
    const [optionsArray, setOptionsArray] = useState(options)

    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                    setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSetFromData = (e) => {
        let $this = e.target
        let data = {
            ...resultFilterData,
            [$this.name]: $this.value
        }
        searchParams.set($this.name, $this.value)
        setSearchParams(searchParams)
        setResultFilterData(data)
    }

    const { handleGetPostList } = useGetData('fetchSchoolReopenList', {
        onSuccess: () => { }
    })

    const { handlePostData } = usePostData({ queryName: "fetchSchoolReopenList", url: "createReopenDate" })
    const handleFilterFromData = (e) => {
        e.preventDefault()
        handleGetPostList.mutate(resultFilterData, {
            onSuccess: (data, variables, context) => {
                setStandardData(data.data)
            },
        })
    }


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const saveReopenDate = (e, idx, standard_id) => {
        let temp = [...standardData]
        let value = e.target.value
        temp[idx].reopen_date = value
        setStandardData(temp)
        optimizedFn(value, standard_id)
    }

    const saveDateToServer = (date, standard_id) => {
        handlePostData.mutate({ standard_id, year_id: resultFilterData.year_id, reopen_date: date })
    }

    const optimizedFn = useCallback(debounce(saveDateToServer), []);



    useEffect(() => {
        const typeList = ["Year"]
        typeList.forEach((type) => {
            fetchOptionData(type)
        })
    }, [])
    return (
        <Layout>
            <div>
                <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
                    <div className='grid grid-cols-5 gap-x-4 items-end'>
                        <FormElement
                            label={"Select Year"}
                            name={'year_id'}
                            element={'select'}
                            required={true}
                            value={resultFilterData.year_id}
                            optionValue={optionsArray.yearArray}
                            errorMessage='Please Select Year!'
                            onChange={handleSetFromData}
                        />
                        <div className='flex'>
                            <button className='primaryButton w-full'>Fetch Standard List</button>
                        </div>
                    </div>
                </form>
            </div>
            {handleGetPostList.isLoading
                ? <Spinner />
                : handleGetPostList?.data?.data.length === 0 || !handleGetPostList?.data?.data ? null : <>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Standard Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Reopen Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {standardData?.map((std, idx) => {
                                    const { standard_id, name, reopen_date } = std
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId text-center">
                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                {idx + 1}
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 lastName">
                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                {name}
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 lastName text-center">
                                            <input type="date" className=' w-1/2 border border-gray-300 rounded px-2 py-1' value={reopen_date} name="reopen_date" onChange={(e) => saveReopenDate(e, idx, standard_id)} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </Layout>
    )
}

export default SchoolReopen