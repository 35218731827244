import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Spinner from '../../components/spinner';
import CreateFeeBook from './CreateFeeBook';
import { PlusIcon } from '@heroicons/react/solid';
import useGetFeeBook from './useGetFeeBook';
import Errors from '../errors';
import EditFeeBook from './EditFeeBook';
import Pagination from '../../components/paginate';
import FormElement from '../../components/Form/FormElement';
import { getList } from '../../api/collections';
import { Link } from 'react-router-dom';

function Fees() {
    const initialState = {
        type: 'Listing',
        limit: 10,
        pageNo: 0,
    }
    const [openCreateDialog, setOpenCreateDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { setGetFormData, getFormData, handleGetDataList } = useGetFeeBook(initialState, 'feeBookListing')
    const [fee_book_id, setFeeBookId] = useState('')
    const [selectedFeeBookData, setSelectedFeeBookData] = useState({
        standard: '',
        year: ''
    })
    const [resultFilterData, setResultFilterData] = useState({ year_id: '' })

    const options = {
        standardArray: [],
        divisionArray: [],
        yearArray: [],
        quotaArray: [],
    }
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Year"]

        typeList.forEach((type) => {
            fetchOptionData(type)
        })

    }, [])

    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setResultFilterData({ ...resultFilterData, year_id: tempId[0].id })
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true)
    }
    const handleEditFeeBook = (e, standard_name, year_name) => {
        e.stopPropagation();
        let element = e.currentTarget
        let id = element.getAttribute('data-id')
        setFeeBookId(id)
        setOpenEditDialog(true)
        setSelectedFeeBookData({
            standard: standard_name,
            year: year_name
        })
    }

    const handlePageClick = (val) => {
        let data = {
            ...getFormData,
            pageNo: val.selected
        }
        setGetFormData(data)
    }

    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...getFormData,
            [e.target.name]: value
        }
        setGetFormData(data)
    }

    const handleSetFromData = (e) => {
        let $this = e.target
        let data = {
            ...resultFilterData,
            [$this.name]: $this.value
        }
        setResultFilterData(data)
    }

    const handleFilterFromData = (e) => {
        e.preventDefault()
        let data = {
            ...getFormData,
            ...resultFilterData,
            pageNo: 0
        }
        setGetFormData(data)
    }



    return (
        <>
            <Layout>
                <div className='flex items-end justify-between'>
                    <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
                        <div className='grid grid-cols-5 gap-x-4 items-end'>
                            <FormElement
                                label={"Select Year"}
                                name={'year_id'}
                                element={'select'}
                                required={true}
                                value={resultFilterData.year_id}
                                optionValue={optionsArray.yearArray}
                                errorMessage='Please Select Year!'
                                onChange={handleSetFromData}
                            />
                            <div className='flex'>
                                <button className='primaryButton w-full'>Fetch Data</button>
                            </div>
                        </div>
                    </form>
                    <div className='flex'>
                        <Link to={`/fees-payment-date`} className='primaryButton text-center'>Fetch Data</Link>
                    </div>
                </div>

                <div>
                    <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
                        <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search...' />
                        <button className='primaryButton' onClick={handleOpenCreateDialog}>
                            <div className='flex items-center space-x-3'>
                                <PlusIcon className='w-6' />
                                <span>Add Fee Book</span>
                            </div>
                        </button>
                    </div>
                    {handleGetDataList?.isLoading ? <Spinner />
                        : handleGetDataList?.status === "error" ? <Errors errorObject={handleGetDataList?.error} inline /> :
                            <>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Standard
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Quota
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Year
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {handleGetDataList?.data?.fee_book_data?.map((standardList, idx) => {
                                                const { standard_name, quota_name, year_name, fee_book_id } = standardList
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(getFormData.limit * (parseInt(getFormData.pageNo) + 1)) - (getFormData.limit - (idx + 1))}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 block text-sm">{standard_name}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">{quota_name}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">{year_name}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center justify-center'>
                                                            <div data-type="Standard" data-action='edit' data-id={fee_book_id} onClick={(e) => handleEditFeeBook(e, standard_name, year_name)}>
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className=" my-7">
                                    <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetDataList?.data?.total_count / getFormData?.limit)} pageNo={handleGetDataList?.data.page_no} />
                                </div>
                            </>
                    }
                </div>
                {openCreateDialog ? <CreateFeeBook open={openCreateDialog} handleClose={() => setOpenCreateDialog(false)} /> : null}
                {openEditDialog ? <EditFeeBook selectedFeeBookData={selectedFeeBookData} open={openEditDialog} handleClose={() => setOpenEditDialog(false)} feeBookId={fee_book_id} /> : null}
            </Layout>
        </>
    )
}

export default Fees