import React, { useCallback, useEffect, useState } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/solid';
import Layout from '../../components/Layout'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';
import Errors from '../errors';
import Spinner from '../../components/spinner';
import MarksEntryModal from './MarksEntryModal';
import Pagination from '../../components/paginate';
import usePostData from '../../hooks/usePostData';
import FormElement from '../../components/Form/FormElement';
import { getList } from '../../api/collections';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import useDebounce from '../../hooks/useDebounce';

const progrees = [
    { value: 'Excellent', name: 'Excellent' },
    { value: 'Very Good', name: 'Very Good' },
    { value: 'Good', name: 'Good' },
    { value: 'Satisfactory', name: 'Satisfactory' },
    { value: 'Not Satisfactory', name: 'Not Satisfactory' },
]
const gradeOption = [
    { value: 'Most of the Time', name: 'Most Of the Time' },
    { value: 'Sometimes', name: 'Sometimes' },
    { value: 'Rarely', name: 'Rarely' },
    { value: 'Always', name: 'Always' },
]
const options = [
    { name: 'Swedish', value: 'sv' },
    { name: 'English', value: 'en' },
];
const StudentRow = ({ it, remarks, input_type, saveGrade, isacademic, student_promoted, schemeIdx, saveMarksAcadamic, marksEditable, saveMarks, idx, student_id, standard_type_id, remarksOptions, remarkChange }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { state } = useLocation()
    return (
        <>
            {input_type === 'remarks' && isacademic === false
                ? <td className="border-dashed border-t border-gray-200 lastName text-center">
                    {marksEditable
                        ? <FormElement
                            className='m-2'
                            readOnly={!marksEditable || student_promoted}
                            disabled={student_promoted}
                            name={'remarks'}
                            element={'select'}
                            value={it.remarks}
                            onChange={(e) => saveMarksAcadamic(e, idx, schemeIdx, student_id, it.scheme_id)}
                            placeholder={'Enter Progress Here...'}
                            optionValue={gradeOption}
                        />
                        :
                        <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                            {it.remarks}
                        </span>
                    }
                </td>
                : input_type === 'remarks' && isacademic === true ?
                    <td className="border-dashed border-t border-gray-200 lastName">
                        {marksEditable
                            ? <FormElement
                                className='m-2'
                                readOnly={!marksEditable || student_promoted}
                                disabled={student_promoted}
                                name={'remarks'}
                                element={'select'}
                                value={it.remarks}
                                onChange={(e) => saveGrade(e, idx, student_id, schemeIdx, it.scheme_id)}
                                placeholder={'Enter Progress Here...'}
                                optionValue={progrees}
                            />
                            : <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                {it.remarks}
                            </span>}
                    </td>
                    :
                    <td className="border-dashed border-t border-gray-200 lastName text-center">
                        <div className='flex items-center justify-center'>
                            <input
                                type="text"
                                name='marks'
                                value={it.marks}
                                disabled={student_promoted}
                                className={`w-10 text-center appearance-none rounded p-1 ${marksEditable ? 'border' : 'border-none outline-none hover:cursor-default'}`}
                                readOnly={!marksEditable || student_promoted}
                                onChange={(e) => saveMarks(e, idx, schemeIdx, student_id, it.scheme_id, it.outoff_marks)} />
                            <span className='px-2'>/</span>
                            <input type="text" name='marks' value={it.outoff_marks} readOnly className={`w-10 text-center appearance-none p-1  text-gray-600`} />
                        </div>
                    </td>}
            {(standard_type_id !== '1' && searchParams.get('std') !== 'IX' && searchParams.get('std') !== 'X') &&
                <td className="border-dashed border-t border-gray-200 lastName text-center">
                    {
                        marksEditable ?
                            remarksOptions ? <SelectSearch search options={remarksOptions} value={it.remarks} name="remarks" placeholder="Select your Remark" onChange={(value) => remarkChange(value, idx, schemeIdx, student_id, it.scheme_id)} /> : ''
                            : (
                                <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                    {it.remarks}
                                </span>
                            )
                    }
                </td>}
        </>
    )
}


const MarksEntryStudentList = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [studentData, setStudentData] = useState(null)
    const [remarksOptions, setRemarksOption] = useState(null)
    const isEditable = searchParams.get('_edit') || false
    const navigate = useNavigate();
    const { state } = useLocation()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [marksEditable, setMarksEditable] = useState(isEditable)
    const [editData, setEditData] = useState(null)
    const { paramsObject, setGetListParams, handleGetData } = useFetchList("getStandardWiseStudentList", { ...JSON.parse(localStorage.getItem("marksEntryQuery")), limit: 10, pageNo: searchParams.get('_p') || 0 })
    const { handlePostData: handleSchemeData } = usePostData({
        queryName: "", url: "createStudentsMarks",
        onSuccessCb: (res) => updateRemarks(res),
        onErrorCb: () => {
            alert('There was a problem adding marks.')
        }
    })

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        searchParams.set('_p', val.selected)
        setSearchParams(searchParams)
        setGetListParams(data)

    }

    const updateRemarks = (res) => {
        // const temp = { ...studentData }
        // const studIdx = temp['data'].findIndex(st => parseInt(res.gradeDetails.student_id) === parseInt(st.student_id))
        // const schemeIdx = temp['data'][studIdx].marksScheme.findIndex(sc => parseInt(sc.scheme_id) === parseInt(res.gradeDetails.scheme_id))
        // temp['data'][studIdx].marksScheme[schemeIdx].remarks = res.gradeDetails.remarks
        // setStudentData(temp)

    }


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const saveMarksToServer = (stdId, schemeId, studIdx, schemeIdx, marksDataArray) => {
        const temp = { ...marksDataArray }
        const tempScheme = temp['data'][studIdx].marksScheme[schemeIdx]
        let queryData = JSON.parse(localStorage.getItem('marksEntryQuery'))
        let data = {
            ...queryData,
            scheme_id: schemeId,
            student_id: stdId,
            ...tempScheme
        }
        handleSchemeData.mutate(data)
        // debounce(handleSchemeData.mutate(data))
    }
    function generatePositiveNumberRegex(upperLimit) {
        if (upperLimit < 0) {
            throw new Error("Upper limit must be a positive number.");
        }

        if (!Number.isInteger(upperLimit)) {
            throw new Error("Upper limit must be an integer.");
        }

        const regexString = `^(?:0|[1-9]\\d{0,${upperLimit.toString().length}})$`;
        return new RegExp(regexString);
    }
    const saveMarks = (e, studIdx, schemeIdx, stdId, schemeId, maxMarks) => {
        const data = { ...studentData }
        const regex = /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?)$/;
        let value = e.target.value
        // if (!value || value === '' || value === 0) {
        //     data['data'][studIdx].marksScheme[schemeIdx][e.target.name] = ''
        //     setStudentData(data)
        // }
        if (+value > +maxMarks) {
            data['data'][studIdx].marksScheme[schemeIdx][e.target.name] = ''
            setStudentData(data)
        } else {
            data['data'][studIdx].marksScheme[schemeIdx][e.target.name] = value
            setStudentData(data)
            optimizedFn(stdId, schemeId, studIdx, schemeIdx, data)
        }
        // let valueTest = regex.test(value);
        // if (valueTest) {
        // } else {
        //     data['data'][studIdx].marksScheme[schemeIdx][e.target.name] = ''
        //     setStudentData(data)
        // }
    }
    const saveMarksAcadamic = (e, studIdx, schemeIdx, stdId, schemeId, maxMarks) => {
        const data = { ...studentData }
        let value = e.target.value
        data['data'][studIdx].marksScheme[schemeIdx][e.target.name] = value
        // console.log(data);
        setStudentData(data)
        optimizedFn(stdId, schemeId, studIdx, schemeIdx, data)
    }

    const optimizedFn = useCallback(debounce(saveMarksToServer), []);

    const remarkChange = (value, studIdx, schemeIdx, stdId, schemeId) => {
        const data = { ...studentData }
        data['data'][studIdx].marksScheme[schemeIdx]['remarks'] = value === 'EMPTY' ? '' : value
        setStudentData(data)
        saveMarksToServer(stdId, schemeId, studIdx, schemeIdx, data)
    }

    const saveGrade = (e, studIdx, stdId, schemeIdx, schemeId) => {
        // console.log({e, studIdx, stdId, schemeIdx, schemeId});
        let value = e.target.value
        const data = { ...studentData }
        data['data'][studIdx]['remarks'] = value
        data['data'][studIdx]['marksScheme'][schemeIdx]['remarks'] = value === 'EMPTY' ? '' : value;
        setStudentData(data)
        let queryData = JSON.parse(localStorage.getItem('marksEntryQuery'))
        let gradedata = {
            ...queryData,
            scheme_id: schemeId,
            student_id: stdId,
            remarks: e.target.value
        }
        handleSchemeData.mutate(gradedata)
    }

    const handleEditMarks = () => {
        searchParams.set('_edit', true)
        setSearchParams(searchParams)
        setMarksEditable(true)
    }

    const handleCloseMarks = () => {
        searchParams.set('_edit', false)
        setSearchParams(searchParams)
        setMarksEditable(false)
    }

    const handleOpenEditModal = (id) => {
        setEditData(id)
        setIsEditModalOpen(true)
    }

    const onClose = () => {
        setIsEditModalOpen(false)
        setEditData(null)
    }

    const fetchOptionsData = () => {
        getList('Remarks')
            .then(res => {
                setRemarksOption(res.data);
            })
    }

    useEffect(() => {
        fetchOptionsData();
    }, [])

    useEffect(() => {
        // if (handleGetData.isSuccess) {
        setStudentData(handleGetData?.data)
        // }
    }, [handleGetData.data])

    return (
        <>
            <Layout >
                <div className='mb-2'>
                    <div className='flex justify-between items-center mt-8'>
                        <div className='flex items-center space-x-4'>
                            <button onClick={() => navigate(-3, { replace: true })} className='flex items-center'>
                                <ChevronLeftIcon className='w-6' />
                                <span className='text-lg underline'>Marks Entry / Subject</span>
                            </button>
                            {handleSchemeData.isLoading &&
                                <span className='flex items-center justify-center'>
                                    <div className='w-6 h-6'>
                                        <Spinner className={'w-full h-full my-0'} />
                                    </div>
                                    <span>
                                        Saving.....
                                    </span>
                                </span>}
                        </div>
                        <div>
                            {
                                !marksEditable ? (
                                    <button onClick={handleEditMarks} className='primaryButton'>Edit</button>
                                ) : (
                                    <button onClick={handleCloseMarks} className='primaryButton'>Save</button>
                                )
                            }

                        </div>
                    </div>
                </div>
                <div className='my-6 flex flex-row'>
                    <p className='font-semibold'>Standard: <span className='font-normal'>{searchParams.get('std')}</span></p>
                    <p className='font-semibold ml-4'>Year: <span className='font-normal'>{searchParams.get('year')}</span></p>
                    <p className='font-semibold ml-4 '>Subject: <span className='font-normal'>{searchParams.get('sub')}</span></p>
                </div>


                {handleGetData?.isLoading ? <Spinner />
                    : handleGetData?.status === "error" ? <Errors errorObject={handleGetData?.error} inline /> :
                        <>
                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Student Name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Division
                                            </th>
                                            {studentData?.scheme_header.length <= 0
                                                ? <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Remark
                                                </th>
                                                : <>
                                                    {
                                                        studentData?.scheme_header.map((it) => {
                                                            return (
                                                                <>
                                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                        {it.marks_scheme}
                                                                    </th>
                                                                    {(studentData?.standard_type_id !== '1' && searchParams.get('std') !== 'IX' && searchParams.get('std') !== 'X') &&
                                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                            {it.marks_scheme} Remarks
                                                                        </th>}
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentData?.data.map((data, idx) => {
                                            const { student_name, roll_no, division_name, standard_name, student_id, marksScheme, student_promoted } = data
                                            return <tr key={idx} className={`${student_promoted && 'bg-green-100'}`}>
                                                <td className="border-dashed border-t border-gray-200 userId w-20">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {(paramsObject.limit * (parseInt(paramsObject.pageNo) + 1)) - (paramsObject.limit - (idx + 1))}
                                                        {student_promoted && <span className=' text-orange-700 bg-orange-100 rounded-full px-4 py-1 ml-3 font-semibold'>Promoted</span>}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-xs">
                                                        {student_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                        {division_name}
                                                    </span>
                                                </td>
                                                {
                                                    marksScheme.map((it, schemeIdx) => {
                                                        return (
                                                            <StudentRow
                                                                student_promoted={student_promoted}
                                                                isGrade={studentData?.isgrade}
                                                                standard_type_id={studentData?.standard_type_id}
                                                                it={it}
                                                                schemeIdx={schemeIdx}
                                                                marksEditable={marksEditable}
                                                                saveMarks={saveMarks}
                                                                saveMarksAcadamic={saveMarksAcadamic}
                                                                remarkChange={remarkChange}
                                                                idx={idx}
                                                                standard_name={standard_name}
                                                                student_id={student_id}
                                                                remarksOptions={remarksOptions}
                                                                isacademic={studentData?.isacademic}
                                                                input_type={studentData?.input_type}
                                                                saveGrade={saveGrade}
                                                            />
                                                        )
                                                    })
                                                }
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className=" my-7">
                                <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetData?.data?.total_count / paramsObject.limit)} pageNo={handleGetData?.data?.page_no} />
                            </div>
                        </>
                }
            </Layout>
            {isEditModalOpen && <MarksEntryModal studentId={editData} open={isEditModalOpen} onClose={onClose} />}
        </>
    )
}

export default MarksEntryStudentList