import React, { useEffect } from 'react'
import { Alert, Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { PREVIOUS_SCHOOLS_DETAILS, STUDENT_ADMISSION_DETAILS, STUDENT_PERSONAL_DETAILS } from './studentInputs';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import useEditStudent from './useEditStudent';
import usePostData from '../../hooks/usePostData';
import { useGetData } from '../../hooks/useGetData';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const studentStatus = [
    { name: 'Active', value: '1' },
    { name: 'Left', value: '2' },
]
function EditStudent({ open, handleClose, data, options }) {
    const [studentData, setStudentData] = useState(data)
    const [selectedTab, setSelectedTab] = useState(1)
    const { editPersonalDetail, editAdmissionDetail, editPreviousDetail, error, success, onError, onSuccess } = useEditStudent()
    const onTabChange = (idx) => {
        setSelectedTab(idx)
        onSuccess(false)
        onError(false)
    }
    const handleSetFormData = (e) => {
        let value = e.target.value
        let data = {
            ...studentData,
            [e.target.name]: value
        }
        setStudentData(data)
    }
    const handleSetFormDataFeeBookNumber = (e) => {
        let value = e.target.value
        let data = {
            ...studentData,
            fee_book_no_wo_prefix: value
        }
        setStudentData(data)
    }
    const handleSavePersonalDetail = (e) => {
        e.preventDefault()
        editPersonalDetail.mutate(studentData)
    }

    const handleEditAdmissionDetail = (e) => {
        e.preventDefault()
        editAdmissionDetail.mutate(studentData)
    }
    const handleEditPreviousSchoolDetail = (e) => {
        e.preventDefault()
        editPreviousDetail.mutate(studentData)
    }

    const [feebookId, setFeeBookId] = useState(data?.fee_book_no_wo_prefix || '')
    const [feeBookType, setFeeBookType] = useState('manual')
    const handleChangeFeeBookInputType = (e) => {
        setFeeBookType(e.target.value)
        setMessage('')
        setErrMessage('')
    }
    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const { handlePostData } = usePostData({
        url: 'feebookNumberAllowed',
        onSuccessCb: (res) => {
            setErrMessage('')
            setMessage(res?.message)
        },
        onErrorCb: (err) => {
            let erroreMsg = err?.response?.data?.message;
            if (erroreMsg === 'Fee Book Number already being used') {
                setErrMessage(erroreMsg)
            }
            setMessage('')
        }
    })
    const handleSetFeeBookNumberManually = (e) => {
        setMessage('')
        setErrMessage('')
        let fee_book_no = e.target.value
        let data = {
            fee_book_no,
            year_id: studentData['join_year'],
            standard_id: studentData['class_std']
        }
        let fd = {
            ...studentData,
            fee_book_no_wo_prefix: fee_book_no,
        }
        setFeeBookId(fee_book_no)
        handleSetFormData(e)
        if (fee_book_no === '') return
        handlePostData.mutate(data)
        setStudentData(fd)
    }
    const { handleGetPostList } = useGetData('generateMidYearFeeBook', {
        onSuccess: (res) => {
            setFeeBookId(res.data)
            let data = {
                ...studentData,
                fee_book_no_wo_prefix: res.data
            }
            // console.log(data);
            setStudentData(data)
        }
    })
    const handleGenerateFeebookNo = () => {
        if (studentData['join_year'] && studentData['class_std']) {
            // generate feebook => generateMidYearFeeBook
            handleGetPostList.mutate({
                join_year: studentData['join_year'],
                class_std: studentData['class_std']
            })
        }
    }
    const generatePreFix = useGetData('getPrefix', {
        onSuccess: (res) => {
            console.log(res.data)
        }
    })

    useEffect(() => {
        let data = {
            standard_id: studentData.class_std
        }
        generatePreFix.handleGetPostList.mutate(data)
    }, [studentData.class_std])
    if (!open) return null
    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className='flex items-center space-x-4'>
                            {/* Student Added */}
                            <h1 className='heading'>Edit Student</h1>  
                            {data?.left_status === '1' && <span className=' rounded-full bg-red-100 text-red-600 px-4 py-1 font-semibold'>Student Left</span>}
                        </div>
                    </div>
                    <div>
                        <ul className='flex items-center justify-center space-x-4 mt-4'>
                            {['Personal Details', 'Admission Details', 'Previous School Details'].map((item, idx) => {
                                return <li onClick={() => onTabChange(idx + 1)} key={idx} className={`editStudent-tab  ${idx + 1 === selectedTab && 'active'}`}>{item}</li>
                            })}
                        </ul>
                        <div className='tab-container mt-10'>
                            <div className={`tab-content presonalDetails ${selectedTab === 1 && 'active'}`}>
                                <div className='presonal_detail--section space-y-4'>
                                    <form onSubmit={handleSavePersonalDetail}>
                                        <div className="grid grid-cols-3 gap-4">
                                            {STUDENT_PERSONAL_DETAILS.map((personal_input, idx) => {
                                                const { optionValue } = personal_input
                                                const { name } = personal_input
                                                return <FormElement
                                                    key={idx}
                                                    {...personal_input}
                                                    optionValue={optionValue?.length > 0 ? optionValue : name === 'class_std' ? options.standardArray : name === 'quota' ? options.quotaArray : name === 'join_year' ? options.yearArray : options.divisionArray}
                                                    value={studentData[name]}
                                                    onChange={handleSetFormData}
                                                />
                                            })}
                                            <FormElement
                                                label={"Student Status"}
                                                name={'student_status'}
                                                element={'select'}
                                                required={true}
                                                value={studentData.student_status}
                                                optionValue={studentStatus}
                                                errorMessage='Please Select Year!'
                                                onChange={handleSetFormData}
                                            />
                                        </div>
                                        <div className='text-center mt-10'>
                                            {editPersonalDetail.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                                        </div>
                                        {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{editPersonalDetail?.error?.response?.data.message}, Please try again later</Alert>}
                                        {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Updated Succesfully</Alert>}
                                    </form>
                                </div>
                            </div>
                            <div className={`tab-content admissionDetails ${selectedTab === 2 && 'active'}`}>
                                <div className='presonal_detail--section'>
                                    <form className='space-y-4' onSubmit={handleEditAdmissionDetail}>
                                        <div className="grid grid-cols-2 gap-4">
                                            {STUDENT_ADMISSION_DETAILS.map((personal_input, idx) => {
                                                const { name, hasAutoGenerate } = personal_input
                                                return !hasAutoGenerate
                                                    ? <FormElement
                                                        key={idx}
                                                        {...personal_input}
                                                        value={studentData[name]}
                                                        onChange={handleSetFormData}
                                                    />
                                                    : <>
                                                        <div>
                                                            <div className='flex items-center space-x-4'>
                                                                <div>
                                                                    <input onChange={handleChangeFeeBookInputType} checked={feeBookType === 'manual' && true} type="radio" id="age1" name="feeBookType" value="manual" />
                                                                    <label className='ml-2 font-semibold text-xl' for="age1">Manual</label>
                                                                </div>
                                                                <div>
                                                                    <input onChange={handleChangeFeeBookInputType} checked={feeBookType === 'auto' && true} type="radio" id="age2" name="feeBookType" value="auto" />
                                                                    <label className='ml-2 font-semibold text-xl' for="age2">Auto</label>
                                                                </div>
                                                            </div>
                                                            {feeBookType === 'auto'
                                                                ? <div className='flex items-end mt-4 space-x-5'>
                                                                    <div className=' flex-[10%]'>
                                                                    <p className='input'>
                                                                        {generatePreFix.handleGetPostList.data?.data}
                                                                    </p>
                                                                </div>
                                                                    <div className=' flex-[100%]'>
                                                                        <FormElement
                                                                            key={idx}
                                                                            info={!studentData['join_year'] || !studentData['class_std'] ? '( Please Enter join year, and class / standard )' : ''}
                                                                            name='fee_book_no_wo_prefix'
                                                                            {...personal_input}
                                                                            value={feebookId}
                                                                            onChange={handleSetFormDataFeeBookNumber}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        disabled={!studentData['join_year'] || !studentData['class_std'] || handleGetPostList.isLoading}
                                                                        onClick={handleGenerateFeebookNo}
                                                                        type='button'
                                                                        className='w-full whitespace-nowrap bg-[color:var(--color5)] h-[2.6rem] rounded-md py-2 px-3 ml-6 text-white disabled:cursor-not-allowed' >
                                                                        <div className='flex items-center justify-center space-x-2'>
                                                                            <span>
                                                                                Fetch Feebook No
                                                                            </span>
                                                                            {handleGetPostList.isLoading &&
                                                                                <svg className={'animate-spin  h-5 w-5 text-white'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                                </svg>}
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                                : feeBookType === 'manual'
                                                                    ? <div className='mt-4'>
                                                                        <div className='flex items-end space-x-5'>
                                                                        <div className=' flex-[5%]'>
                                                                    <p className='input'>
                                                                        {generatePreFix.handleGetPostList.data?.data}
                                                                    </p>
                                                                </div>
                                                                            <div className='flex-[100%]'>
                                                                                <FormElement
                                                                                    key={idx}
                                                                                    info={!studentData['join_year'] || !studentData['class_std'] ? '( Please Enter join year, and class / standard )' : ''}
                                                                                    name='fee_book_no_wo_prefix'
                                                                                    {...personal_input}
                                                                                    value={feebookId}
                                                                                    disabled={!studentData['join_year'] || !studentData['class_std'] ? true : false}
                                                                                    onChange={handleSetFeeBookNumberManually}
                                                                                />
                                                                            </div>
                                                                            {handlePostData.isLoading && <Spinner />}
                                                                        </div>
                                                                        <p className=' text-green-700 font-semibold'>{message}</p>
                                                                        <p className=' text-red-700 font-semibold'>{errMessage}</p>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </>
                                            })}
                                        </div>
                                        <div className='text-center mt-10'>
                                            {editAdmissionDetail.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                                        </div>
                                        {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{editPersonalDetail?.error?.response?.data.message}, Please try again later</Alert>}
                                        {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Updated Succesfully</Alert>}
                                    </form>
                                </div>
                            </div>
                            <div className={`tab-content previousSchoolDetail ${selectedTab === 3 && 'active'}`}>
                                <div className='presonal_detail--section space-y-4'>
                                    <form className='space-y-4' onSubmit={handleEditPreviousSchoolDetail}>
                                        <div className="grid grid-cols-3 gap-4">
                                            {PREVIOUS_SCHOOLS_DETAILS.map((personal_input, idx) => {
                                                const { name } = personal_input
                                                return <FormElement
                                                    key={idx}
                                                    {...personal_input}
                                                    value={studentData[name]}
                                                    onChange={handleSetFormData}
                                                />
                                            })}
                                        </div>
                                        <div className='text-center mt-10'>
                                            {editPreviousDetail.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                                        </div>
                                        {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{editPersonalDetail?.error?.response?.data.message}, Please try again later</Alert>}
                                        {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Updated Succesfully</Alert>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default EditStudent