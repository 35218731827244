import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import FormElement from '../../components/Form/FormElement'
import { getList } from '../../api/collections'
import usePostData from '../../hooks/usePostData'
import useFetchList from '../../hooks/useFetchList'
import { IconButton, Snackbar } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteStudent from '../students/DeleteStudent'
import Spinner from '../../components/spinner'
import MuiAlert from '@mui/material/Alert';
import { useDeleteDataFromServer } from '../../hooks/useDeleteItem'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const FeeDate = () => {
    const initialState = {
        limit: 50,
        pageNo: 0,
        year_id: '2',
    }
    const [resultFilterData, setResultFilterData] = useState({
        year_id: '',
        standard_type_id: '',
        standard_type: '',
        name: '',
        date: '',
        date_order: ''
    })
    const options = {
        standardArray: [],
        standardTypeOptions: [],
        divisionArray: [],
        yearArray: [],
        quotaArray: [],
    }
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false)
    const { handlePostData } = usePostData({
        url: 'addFeeBookPaymentDates',
        onSuccessCb: () => {
            setIsCreateSuccess(true)
            setHandleCreateFeeDate(false)
        }
    })
    const editFormData = usePostData({
        url: 'updateFeeBookPaymentDates',
        onSuccessCb: () => {
            setIsUpdateSuccess(true)
            setEditModalOpen(false)
        }
    })
    const { handleGetData, paramsObject, setGetListParams } = useFetchList('listFeeBookPaymentDates', initialState)
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Year", "StandardType"]
        typeList.forEach((type) => {
            fetchOptionData(type)
        })

    }, [])
    const handleSetFromData = (e) => {
        let data = {
            ...resultFilterData,
            [e.target.name]: e.target.value
        }
        setResultFilterData(data)
    }
    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setResultFilterData({ ...resultFilterData, year_id: tempId[0].id })
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                } else if (type === "StandardType") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        standardTypeOptions: res.data
                    }));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleFilterFromData = (e) => {
        e.preventDefault()
        handlePostData.mutate(resultFilterData)
    }
    const [createFeesDate, setHandleCreateFeeDate] = useState(false)
    const handleCreateFeesDate = () => {
        setHandleCreateFeeDate(true)
    }
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [feeLabelId, setFeeLabelId] = useState(null)
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const { setDeleteData } = useDeleteDataFromServer('deleteFeeBookPaymentDates', '',
        {
            onSuccesCB: () => {
                setEditModalOpen(false)
                setIsDeletedSuccess(true)
            }
        }
    )
    const [editData, setEditData] = useState({})
    const handleEditFeeBook = (id) => {
        setEditModalOpen(true)
        setFeeLabelId(id)
        let data = handleGetData.data?.data?.filter(x => x.id === id)
        setEditData(data[0])
    }
    const handleChangeEditData = (e) => {
        let data = {
            ...editData,
            [e.target.name]: e.target.value
        }
        setEditData(data)
    }
    const handleUpdateFromData = (e) => {
        e.preventDefault()
        editFormData.handlePostData.mutate(editData)
    }
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleDeleteFeeDate = () => {
        let data = {
            id: feeLabelId
        }
        setOpenDeleteModal(true)
        // var result = confirm("Are you sure you want to proceed?")
        // setDeleteData.mutate(data)
    }
    const deletedSuccess = () => {
        setEditModalOpen(false)
        setIsDeletedSuccess(true)
        setOpenDeleteModal(false)
    }
    return (
        <>
            {(setDeleteData.isLoading || editFormData.handlePostData.isLoading || handlePostData.isLoading) &&
                <div className='fixed top-0 left-0 w-full h-full bg-[#ffffff75] z-50 flex items-center justify-center'>
                    <div className=' mt-10'>
                        <Spinner />
                    </div>
                </div>}
            <Layout>
                <div className='text-right'>
                    <button onClick={handleCreateFeesDate} className='primaryButton'>Create Fees Date</button>
                </div>
                {(handleGetData?.isLoading || handleGetData.isFetching)
                    ? <Spinner />
                    : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative my-4">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Standard Type
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Date
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Year
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {handleGetData?.data?.data?.map((list, idx) => {
                                    const { date, id, name, standard_type_name,year_name } = list
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(paramsObject.limit * (parseInt(paramsObject.pageNo) + 1)) - (paramsObject.limit - (idx + 1))}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 block text-sm">{name}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 block text-sm">{standard_type_name}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">{date}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">{year_name}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                            <div className='flex items-center justify-center'>
                                                <div data-type="Standard" data-action='edit' data-id={id} onClick={() => handleEditFeeBook(id)}>
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>
                                                {/* <div data-type="Standard" data-action='edit' data-id={id} onClick={() => handleEditFeeBook(id)}>
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                {createFeesDate &&
                    <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffff9e] z-50'>
                        <div className=' bg-white w-1/2 rounded mx-auto mt-20 shadow-xl'>
                            <form className='py-4 px-4 space-y-4' onSubmit={handleFilterFromData}>
                                <div className='grid grid-cols-3 gap-4 items-start'>
                                    <FormElement
                                        label={"Select Year"}
                                        name={'year_id'}
                                        element={'select'}
                                        required={true}
                                        value={resultFilterData.year_id}
                                        optionValue={optionsArray.yearArray}
                                        errorMessage='Please Select Year!'
                                        onChange={handleSetFromData}
                                    />
                                    <FormElement
                                        label={"Select Standard Type"}
                                        name={'standard_type_id'}
                                        element={'select'}
                                        required={true}
                                        errorMessage='Please Select Standard Type!'
                                        onChange={handleSetFromData}
                                        optionValue={optionsArray.standardTypeOptions}
                                        value={resultFilterData.standard_type_id}
                                    />
                                    {resultFilterData.standard_type_id === '3' &&
                                        <div>
                                            <label htmlFor="standardType" className='label'>Standard <span className='mandatory'>*</span></label>
                                            <select name="standard_type" value={resultFilterData.standard_type} className='input' required={true} onChange={handleSetFromData}>
                                                <option value="">--Select--</option>
                                                <option value={'5_to_9'}>5 to 9</option>
                                                <option value={'10'}>10</option>
                                            </select>
                                        </div>}
                                </div>
                                <div className='grid grid-cols-3 gap-4 mt-4 items-start'>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Name <span className='mandatory'>*</span></label>
                                        <input type="text" name='name' className='input' value={resultFilterData.name} required={true} onChange={handleSetFromData} />
                                    </div>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Date <span className='mandatory'>*</span></label>
                                        <input type="text" name='date' className='input' value={resultFilterData.date} required={true} onChange={handleSetFromData} />
                                    </div>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Order <span className='mandatory'>*</span></label>
                                        <input type="text" name='date_order' className='input' value={resultFilterData.date_order} required={true} onChange={handleSetFromData} />
                                    </div>
                                </div>
                                <div className=' space-x-4'>
                                    <button className=' primaryButton'>Save</button>
                                    <button onClick={() => setHandleCreateFeeDate(false)} type='button' className=' border rounded px-4 py-2'>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>}
                {isEditModalOpen &&
                    <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffff9e] z-40'>
                        <div className=' bg-white w-1/2 rounded mx-auto mt-20 shadow-xl'>
                            <form className='py-4 px-4 space-y-4' onSubmit={handleUpdateFromData}>
                                <div className='grid grid-cols-3 gap-4 items-start'>
                                    <FormElement
                                        label={"Select Year"}
                                        disabled={true}
                                        name={'year_id'}
                                        element={'select'}
                                        required={true}
                                        value={editData.year_id}
                                        optionValue={optionsArray.yearArray}
                                        errorMessage='Please Select Year!'
                                        onChange={handleSetFromData}
                                    />
                                    <FormElement
                                        label={"Select Standard Type"}
                                        name={'standard_type_id'}
                                        disabled={true}
                                        element={'select'}
                                        required={true}
                                        errorMessage='Please Select Standard Type!'
                                        onChange={handleSetFromData}
                                        optionValue={optionsArray.standardTypeOptions}
                                        value={editData.standard_type_id}
                                    />
                                    {editData.standard_type_id === '3' &&
                                        <div>
                                            <label htmlFor="standardType" className='label'>Standard <span className='mandatory'>*</span></label>
                                            <select disabled={true} name="standard_type" value={editData.standard_type} className='input' required={true} onChange={handleSetFromData}>
                                                <option value="">--Select--</option>
                                                <option value={'5_to_9'}>5 to 9</option>
                                                <option value={'10'}>10</option>
                                            </select>
                                        </div>}
                                </div>
                                <div className='grid grid-cols-3 gap-4 mt-4 items-start'>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Name <span className='mandatory'>*</span></label>
                                        <input type="text" name='name' className='input' value={editData.name} required={true} onChange={handleChangeEditData} />
                                    </div>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Date <span className='mandatory'>*</span></label>
                                        <input type="text" name='date' className='input' value={editData.date} required={true} onChange={handleChangeEditData} />
                                    </div>
                                    <div>
                                        <label htmlFor="standardType" className='label'>Order <span className='mandatory'>*</span></label>
                                        <input disabled={true} type="text" name='date_order' className='input' value={editData.date_order} required={true} onChange={handleChangeEditData} />
                                    </div>
                                </div>
                                <div className=' space-x-4'>
                                    <button className=' primaryButton'>Update</button>
                                    <button onClick={handleDeleteFeeDate} className=' bg-[#e63946] text-white px-4 py-2 rounded'>Delete</button>
                                    <button onClick={() => setEditModalOpen(false)} type='button' className=' border rounded px-4 py-2'>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>}

                {openDeleteModal && <DeleteStudent open={openDeleteModal} id={feeLabelId} onClose={() =>setOpenDeleteModal(false) } onDelete={deletedSuccess} url={'deleteFeeBookPaymentDates'} />}
                <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                    <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Fee Date Deleted Succesfully
                    </Alert>
                </Snackbar>
                <Snackbar open={isCreateSuccess} autoHideDuration={6000} onClose={() => setIsCreateSuccess(false)}>
                    <Alert onClose={() => setIsCreateSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Fee Created Succesfully
                    </Alert>
                </Snackbar>
                <Snackbar open={isUpdateSuccess} autoHideDuration={6000} onClose={() => setIsUpdateSuccess(false)}>
                    <Alert onClose={() => setIsUpdateSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Fee Updated Succesfully
                    </Alert>
                </Snackbar>
            </Layout>
        </>
    )
}

export default FeeDate